import { Button, Table, Tooltip } from 'antd';
import React from 'react';
import { CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { BACKEND_URL } from '../../../../config/config';

const AdvancedCourseSchedulingAndUtilizationReportSummaryTable = ({ 
    table_list,
    course_section_filter,
    classroom_location_filter,
    course_type_filter,
    instructor_filter,
    time_slot_filter,
    enrollment_capacity_filter,
    program_major_filter
}) => {

    const columns = [
        {
            title: "Course Name",
            dataIndex: "course_name",
            key: "course_name",
        },
        {
            title: "Course Code",
            dataIndex: "course_code",
            key: "course_code",
        },
        {
            title: "Instructor",
            render: (text, record) => (
                <>{record?.instructor?.name}</>
            ),
        },
        {
            title: "Section",
            dataIndex: "section",
            key: "section",
        },
        {
            title: "Classroom Location",
            render: (text, record) => (
                <>{`${record?.classroom?.building} - ${record?.classroom?.room_number}`}</>
            ),
        },
        {
            title: "Classroom Size",
            dataIndex: "classroom_size",
            key: "classroom_size",
        },
        {
            title: "Enrollment",
            dataIndex: "enrollment",
            key: "enrollment",
        },
        {
            title: "Time & Date",
            render: (text, record) => (
                <>{`${record?.time_slot} - ${record?.date}`}</>
            ),
        },
    ];

    const DOWNLOADPDF = () => {
        const token = JSON.parse(localStorage.getItem('sis_user_data')).token;
        const courseSection = course_section_filter;
        const location = classroom_location_filter;
        const type = course_type_filter;
        const instructor = instructor_filter;
        const timeSlot = time_slot_filter;
        const enrollmentCap = enrollment_capacity_filter;
        const programMajor = program_major_filter;

        window.open(
            `${BACKEND_URL}/csv-course-scheduling-utilization-reports/${token}/${courseSection}/${location}/${type}/${instructor}/${timeSlot}/${enrollmentCap}/${programMajor}`
        );
    };

    return (
        <>
            <div style={{ display: "inline-block", float: "right", marginRight: "10px" }}>
                {/* CSV Download button  */}
                <Tooltip title="Download Course Scheduling and Utilization Report CSV">
                    <Button type='primary'
                        onClick={DOWNLOADPDF}
                        ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                </Tooltip>
                {/* PDF Download button  */}
                <Tooltip title="Download Course Scheduling and Utilization Report PDF">
                    <Button type='primary'
                        onClick={DOWNLOADPDF}
                        ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                </Tooltip>
            </div>
            <Table className="vertical-align-top-table" columns={columns} pagination={true} dataSource={table_list} />
        </>
    );
}

export default AdvancedCourseSchedulingAndUtilizationReportSummaryTable;