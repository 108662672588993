import React, { useEffect, useRef, useState } from 'react';
import { Button, Table, Popconfirm, notification, Input, Tooltip, Pagination } from 'antd';
import { FACULTIES_LIST, STATUS_FACULTY, DELETE_FACULTY, FACULTIES_LISTING_SEARCH } from '../../apis/apis';
import { useNavigate } from 'react-router-dom';
import SisLoader from '../../widgets/loader';
import { DeleteOutlined, EditOutlined, StopOutlined, SmileOutlined, CloudDownloadOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import handleStatusText from 'react-highlight-words';
import { BACKEND_URL } from '../../config/config';
import './style.css'
import { render } from '@testing-library/react';

const FacultiesList = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [tableList, setTableList] = useState([]);
    const [errors, setErrors] = useState([]);
    const [popStatusDescription, set_popStatusDescription] = useState('Do you want to change Status of this User');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [last_page, set_last_page] = useState(1);
    const [total, set_total] = useState("");
    const [per_page, set_per_page] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState("");
    const [search_error, set_search_error] = useState("")
    const debounceTimeout = useRef(null);
    const errorTimeoutRef = useRef(null);
    // Permissions state manage 
    const [all_permissions, set_all_permissions] = useState(false);
    const [permissions, set_permissions] = useState([]);
    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if (localStorgeData.role === 2074) {
            return true;
        }
        var check_permissions = [];
        if (type === 'create') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }

        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    // Search 

    const debounce = (callback, delay) => {
        return (...args) => {
            clearTimeout(debounceTimeout.current);
            debounceTimeout.current = setTimeout(() => {
                callback(...args);
            }, delay);
        };
    };
    
      const handleSearch = debounce((query) => {
        if(query != "")
        {
          SEARCH_LIST_API(query);
        }
        else{
          LIST_API(currentPage)
        }
      }, 800);
    
      const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchQuery(value);
        if (errorTimeoutRef.current) {
          clearTimeout(errorTimeoutRef.current);
        }
        if (value === "") {
          set_search_error("");
          handleSearch(value);
          return;
        }
        if (value.length < 3) {
          errorTimeoutRef.current = setTimeout(() => {
          set_search_error("Please enter at least 3 characters.")
        }, 800);
          return;
        }
        set_search_error("");
        handleSearch(value);
      };
    

    

    const SEARCH_LIST_API = async (UserQuery) => {
        setLoader(true)
        const FACULTIES_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', FACULTIES_DATA.token);
        FORM_DATA.append("search", UserQuery);
        const API_RESPONSE = await FACULTIES_LISTING_SEARCH(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            setTableList(API_RESPONSE?.data?.faculty?.data);
            setFilteredData(API_RESPONSE?.data?.faculty?.data);
            setCurrentPage(API_RESPONSE?.data?.faculty?.current_page)
            set_last_page(API_RESPONSE?.data?.faculty?.last_page)
            set_total(API_RESPONSE?.data?.faculty?.total)
            set_per_page(API_RESPONSE?.data?.faculty?.per_page);
            setLoader(false);
        } else {
            setLoader(false);
        }
    };

    const STATUS_API = async (id) => {
        setLoader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await STATUS_FACULTY(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Faculty status successfully changed.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/facultiesList');
        } else {
            setErrors(API_RESPONSE.data.errors);
            setLoader(false);
        }
    };

    const DELETE_API = async (id) => {
        setLoader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await DELETE_FACULTY(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Faculty successfully deleted.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/facultiesList');
        } else {
            setErrors(API_RESPONSE.data.errors);
            setLoader(false);
        }
    };


    const handleStatusText = (id, status) => {
        if (status == 1) {
            set_popStatusDescription('Do you want to make this user Inactive');
        } else { set_popStatusDescription('Do you want to make this user Active'); }
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <>
                        <span style={{ textTransform: "capitalize" }}>{record.honorific}</span> {record.name}
                    </>
                )
            },
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 150
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            className: 'custom-address-column',

        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            className: 'custom-department-column'
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span className={record.status ? 'table-status-activate' : 'table-status-deactivate'}>
                    {record.status ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'Action',
            render: (text, record) => (
                <>
                    {check_permissions('faculties', 'status') && <Popconfirm
                        title="Change Status"
                        description={popStatusDescription}
                        onConfirm={() => {
                            STATUS_API(btoa(record.id))
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                handleStatusText(record.id, record.status)
                            }}
                            style={{ backgroundColor: "#888888", marginRight: "5px" }}>
                            <div className="tooltip"> <StopOutlined /> <span className="tooltiptext" >Status Change</span> </div>
                        </Button>
                    </Popconfirm>}
                    {check_permissions('faculties', 'update') && <Button
                        type="primary"
                        size="small"
                        onClick={() => navigate('/edit-faculty/' + btoa(record.id))}
                        style={{ marginRight: "5px" }}>
                        <div className="tooltip"> <EditOutlined /> <span className="tooltiptext" style={{ width: '80px' }}>Edit n View</span> </div>
                    </Button>}
                    {check_permissions('faculties', 'delete') && <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this user?"
                        onConfirm={() => DELETE_API(btoa(record.id))}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" size="small" danger>
                            <div className="tooltip"> <DeleteOutlined /> <span className="tooltiptext" style={{ width: '80px' }}>Delete</span> </div>
                        </Button>
                    </Popconfirm>}
                </>
            ),
        },
    ];

    const LIST_API = async (UserSearchPage) => {
        setLoader(true)
        const FACULTIES_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', FACULTIES_DATA.token);
        FORM_DATA.append("page", UserSearchPage ? UserSearchPage : currentPage);
        const API_RESPONSE = await FACULTIES_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            setTableList(API_RESPONSE?.data?.faculty?.data);
            setFilteredData(API_RESPONSE?.data?.faculty?.data);
            setCurrentPage(API_RESPONSE?.data?.faculty?.current_page)
            set_last_page(API_RESPONSE?.data?.faculty?.last_page)
            set_total(API_RESPONSE?.data?.faculty?.total)
            set_per_page(API_RESPONSE?.data?.faculty?.per_page);
            setLoader(false);
        } else {
            setLoader(false);
        }
    };

    // useEffect(() => {
    //    const timer = setTimeout(() => {
    //      setDebouncedQuery(searchQuery);
    //      LIST_API("",searchQuery)
    //    }, 600);
   
    //    return () => clearTimeout(timer); 
    //  }, [searchQuery]);
   

    useEffect(() => {
        LIST_API();
      },[])
   
     const pagination_changes  = (UserSearchPage) => {
       setCurrentPage(UserSearchPage);
       if(searchQuery == "")
        {
       LIST_API(UserSearchPage)
        }
        else{
            SEARCH_LIST_API(searchQuery)
          }
   };

    return (
        <>
        {loader ? <SisLoader/> :
        <div>
            <div>
                <div className='theme-content-head'>
                    <div className='theme-content-left-head'>
                        <h3>Faculties List</h3>
                    </div>
                    <div className='theme-content-right-head'>
                        {check_permissions('faculties', 'create') &&
                            <Button type='primary' style={{width:"156px"}} className='lusisbtn' onClick={() => navigate('/add-faculty')}><PlusOutlined/>Add Faculty</Button>}

                        {/* CSV Download button */}
                        <Tooltip title="Download Faculty List CSV">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/download-faculties/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                        </Tooltip>

                        {/* PDF Download button  */}
                        <Tooltip title="Download Faculty List PDF">
                            <Button type='primary'
                                onClick={() => window.location = BACKEND_URL + '/download-faculties-pdf/' + JSON.parse(localStorage.getItem('sis_user_data')).token} ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                        </Tooltip>


                    </div>
                </div>
                <div className='theme-content-head'>
                    <div className='input-box'>
                        <Input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleInputChange}
                        />
                        {search_error && (
                  <>
                  <br/>
                  <span style={{ color: "red" }}>{search_error}</span>
                  </>
                )}
                    </div>
                </div>
                {loader ? <SisLoader /> : 
                <>
                <Table columns={columns} dataSource={filteredData} pagination={false} />
                <div style={{float:"right", marginTop:"20px"}}><Pagination onChange={pagination_changes} defaultCurrent={parseInt(currentPage)} total={parseInt(total)} pageSize={parseInt(per_page)} /></div>
                </>
                }

            </div>
        </div>
    }
        </>
    );
};

export default FacultiesList;