import React, { useEffect, useRef, useState } from "react";
// import { Button, Popconfirm, Table, notification, Tooltip } from 'antd';
import "../students/style.css";
import {
  TableColumnsType,
  Button,
  TableProps,
  Popconfirm,
  Table,
  notification,
  Input,
  Tooltip,
  Pagination,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  USERS_LIST,
  STATUS_USER,
  DELETE_USER,
  STATUS_STUDENT,
  DELETE_STUDENT,
  ENROLLMENTS_LIST,
  STUDENTS_ENROLLMENTS_LIST,
  STUDENTS_SELF_ENROLLMENTS_LIST,
  SELF_ENROLLMENTS_LISTING_SEARCH,
} from "../../apis/apis";
import {
  DeleteOutlined,
  FilePdfOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  SmileOutlined,
  StopOutlined,
  EyeFilled,
} from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import { BACKEND_URL } from "../../config/config";



const StudentOnlineApplicationList = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const [popStatusDescription, set_popStatusDescription] = useState(
      "Do you want to change Status of Student"
    );
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [last_page, set_last_page] = useState(1);
    const [total, set_total] = useState("");
    const [per_page, set_per_page] = useState("");
     const [debouncedQuery, setDebouncedQuery] = useState("");
     const [search_error, set_search_error] = useState("")
       const debounceTimeout = useRef(null);
       const errorTimeoutRef = useRef(null);
    
  
    // Form State
    // const [title, set_title] = useState('');
    const [table_list, set_table_list] = useState([]);
    const check_permissions = (module_name, type) => {
      const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
      if (localStorgeData.role === 2074) {
        return true;
      }
      var check_permissions = [];
      if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
      }
      if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
      }
      if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
      }
      if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
      }
  
      if (check_permissions.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    // Search
    // const handleSearch = (e) => {
    //   const query = e.target.value;
    //   setSearchQuery(query);
    // };
  
    const debounce = (callback, delay) => {
      return (...args) => {
          clearTimeout(debounceTimeout.current);
          debounceTimeout.current = setTimeout(() => {
              callback(...args);
          }, delay);
      };
  };
  
    const handleSearch = debounce((query) => {
      if(query != "")
      {
        SEARCH_LIST_API(query);
      }
      else{
        LIST_API(currentPage)
      }
    }, 800);
  
    const handleInputChange = (event) => {
      const value = event.target.value;
      setSearchQuery(value);
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      if (value === "") {
        set_search_error("");
        handleSearch(value);
        return;
      }
      if (value.length < 3) {
        errorTimeoutRef.current = setTimeout(() => {
        set_search_error("Please enter at least 3 characters.")
      }, 800);
        return;
      }
      set_search_error("");
      handleSearch(value);
    };
  
    
       const SEARCH_LIST_API = async (UserQuery) => {
          set_loader(true);
          const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
          const FORM_DATA = new FormData();
          FORM_DATA.append("token", USER_DATA.token);
          FORM_DATA.append("search", UserQuery);
          const API_RESPONSE = await SELF_ENROLLMENTS_LISTING_SEARCH(FORM_DATA);
          if (API_RESPONSE?.data?.status) {
            console.log("API_RESPONSE", API_RESPONSE);
            set_table_list(API_RESPONSE?.data?.enrollments?.data);
            setFilteredData(API_RESPONSE?.data?.enrollments?.data);
            //set_student_id(API_RESPONSE?.data?.enrollments?.student_id)
            setCurrentPage(API_RESPONSE?.data?.enrollments?.current_page);
            set_last_page(API_RESPONSE?.data?.enrollments?.last_page);
            set_total(API_RESPONSE?.data?.enrollments?.total);
            set_per_page(API_RESPONSE?.data?.enrollments?.per_page);
            set_loader(false);
          } else {
            set_loader(false);
          }
        };
    

  
    // Status Api
    const STATUS_API = async (id) => {
      set_loader(true);
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA.token);
      FORM_DATA.append("id", id);
      const API_RESPONSE = await STATUS_STUDENT(FORM_DATA);
      if (API_RESPONSE.data.status) {
        if (API_RESPONSE.data.type === "yes") {
          notification.open({
            message: "Success!!",
            description: API_RESPONSE.data.message,
            icon: <SmileOutlined style={{ color: "green" }} />,
          });
        } else {
          notification.open({
            message: "Note!!",
            description: API_RESPONSE.data.message,
            icon: <SmileOutlined style={{ color: "yellow" }} />,
          });
        }
  
        navigate("/registration-list");
      } else {
        set_errors(API_RESPONSE.data.errors);
        set_loader(false);
      }
    };
  
    // delete api
    const DELETE_API = async (id) => {
      set_loader(true);
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA.token);
      FORM_DATA.append("id", id);
      const API_RESPONSE = await DELETE_STUDENT(FORM_DATA);
      if (API_RESPONSE.data.status) {
        notification.open({
          message: "Success!!",
          description: "Student Successfully deleted.",
          icon: <SmileOutlined style={{ color: "green" }} />,
        });
        console.log(API_RESPONSE);
        navigate("/registration-list");
      } else {
        set_errors(API_RESPONSE.data.errors);
        set_loader(false);
      }
    };
  
    // handle status Text
    const handleStatusText = (id, status) => {
      if (status === 1) {
        set_popStatusDescription("Do you want to make this student Inactive");
      } else {
        set_popStatusDescription("Do you want to make this student Active");
      }
    };
  
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: "Application ID",
        dataIndex: "application_no",
        key: "application_no",
        sorter: (a, b) => a.application_no.localeCompare(b.application_no),
      },
      {
        title: "Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Email",
        dataIndex: "primary_email",
        key: "primary_email",
      },
    //   {
    //     title: "Student Status",
    //     dataIndex: "student_status",
    //     render: (text, record) => {
    //       if (record.student_status == 1) {
    //         return 'Ready for Programme Registration';
    //       }
    //       if (record.student_status == 2) {
    //         return 'Enrolled';
    //       }
    //     },
    //   },
    //   {
    //     title: "Status",
    //     dataIndex: "status",
    //     key: "status",
    //     render: (text, record) => {
    //       if (record.status) {
    //         return <span className="table-status-activate">Active</span>;
    //       } else {
    //         return <span className="table-status-deactivate">Inactive</span>;
    //       }
    //     },
    //   },
      {
        title: "Action",
        key: "Action",
        render: (text, record) => {
          return (
            <>
              {/* {check_permissions('enrollments', 'status') &&
                <Popconfirm
                  title="Change Status"
                  description={popStatusDescription}
                  onConfirm={() => {
                    STATUS_API(btoa(record.id));
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    size="small"
                    style={{ backgroundColor: "#888888", marginRight: "5px" }}
                    onChange={() => handleStatusText(record.id, record.status)}
                  >
                    <div className="tooltip">
                      <StopOutlined />
                      <span className="tooltiptext">Status Change</span>
                    </div>
                  </Button>
                </Popconfirm>}
              {check_permissions('enrollments', 'update') &&
                <Button
                  type="primary"
                  size="small"
                  onClick={() => navigate("/edit-registration/" + btoa(record.id))}
                  style={{ marginRight: "5px" }}
                >
                  <div className="tooltip">
                    <EditOutlined />
                    <span className="tooltiptext">Edit student View</span>
                  </div>
                </Button>}
              {check_permissions('enrollments', 'delete') &&
                <Popconfirm
                  title="Delete Student"
                  description="Are you sure to delete this student?"
                  onConfirm={() => {
                    DELETE_API(btoa(record.id));
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary" size="small" danger>
                    <div class="tooltip">
                      {" "}
                      <DeleteOutlined /> <span class="tooltiptext">
                        Delete
                      </span>{" "}
                    </div>
                  </Button>
                </Popconfirm>} */}
            {(check_permissions('enrollments', 'view') || check_permissions('enrollments', 'update')) &&
            <Button type="primary" size="small" onClick={() => navigate('/edit-student-online-application/' + btoa(record.id))} style={{ marginLeft: "5px" }}><EyeFilled /></Button>}
             {(check_permissions('enrollments', 'view') || check_permissions('enrollments', 'update')) &&
              <Tooltip title="Download Student Application Form PDF">
           <Button type="primary" size="small" ghost onClick={ () => (window.location = BACKEND_URL + "/pdf-enrollment-application-form/" + JSON.parse(localStorage.getItem("sis_user_data")).token + "/" + record?.id) } style={{ marginLeft: "5px" }}><FilePdfOutlined  /></Button>      
           </Tooltip>}
            </>
          );
        },
      },
    ];
    // DB list
    const LIST_API = async (UserSearchPage) => {
      set_loader(true)
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA.token);
      FORM_DATA.append("page", UserSearchPage ? UserSearchPage : currentPage);
      const API_RESPONSE = await STUDENTS_SELF_ENROLLMENTS_LIST(FORM_DATA);
      if (API_RESPONSE?.data?.status) {
        set_table_list(API_RESPONSE?.data?.enrollments?.data);
        setFilteredData(API_RESPONSE?.data?.enrollments?.data);
        setCurrentPage(API_RESPONSE?.data?.enrollments?.current_page);
        set_last_page(API_RESPONSE?.data?.enrollments?.last_page);
        set_total(API_RESPONSE?.data?.enrollments?.total);
        set_per_page(API_RESPONSE?.data?.enrollments?.per_page);
        set_loader(false);
      } else {
        set_loader(false);
      }
    };

  // //  useEffect(() => {
  // //      const timer = setTimeout(() => {
  // //        setDebouncedQuery(searchQuery);
  // //        LIST_API("",searchQuery)
  // //      }, 600);
   
  // //      return () => clearTimeout(timer); 
  // //    }, [searchQuery]);
   
   
  //    const pagination_changes  = (UserSearchPage) => {
  //      setCurrentPage(UserSearchPage);
  //      LIST_API(UserSearchPage,debouncedQuery)
  //  };
  
    useEffect(() => {
        LIST_API();
      },[])
    
      
const pagination_changes = (UserSearchPage) => {
  setCurrentPage(UserSearchPage);
  if(searchQuery == "")
  {
    LIST_API(UserSearchPage);
  }
  else{
    SEARCH_LIST_API(searchQuery)
  }
  
};

  

    return(
      <>
       {loader ? (
          <SisLoader />
        ) : (
        <div>
        <div className="theme-content-head">
          <div className="theme-content-left-head">
            <h3>Online Application</h3>
          </div>
          {/* in future pdf and excel button required then we have to make here */}
        </div>
        <div className="theme-content-head">
          <div className="input-box">
            <Input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleInputChange}
            />
           {search_error && (
                  <>
                  <br/>
                  <span style={{ color: "red" }}>{search_error}</span>
                  </>
                )}
          </div>
        </div>
          <Table columns={columns} dataSource={filteredData} pagination={false} />
          <div style={{float:"right", marginTop:"20px"}}><Pagination onChange={pagination_changes} defaultCurrent={parseInt(currentPage)} total={parseInt(total)} pageSize={parseInt(per_page)} /></div>
      </div>
    )}
      </>
    )
}

export default StudentOnlineApplicationList;